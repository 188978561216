.bs-xs-0{height: 0px;}
.bs-xs-5{height: 5px;}
.bs-xs-10{height: 10px;}
.bs-xs-15{height: 15px;}
.bs-xs-20{height: 20px;}
.bs-xs-25{height: 25px;}
.bs-xs-30{height: 30px;}
.bs-xs-35{height: 35px;}
.bs-xs-40{height: 40px;}
.bs-xs-45{height: 45px;}
.bs-xs-50{height: 50px;}
.bs-xs-55{height: 55px;}
.bs-xs-60{height: 60px;}
.bs-xs-65{height: 65px;}
.bs-xs-70{height: 70px;}
.bs-xs-75{height: 75px;}
.bs-xs-80{height: 80px;}
.bs-xs-85{height: 85px;}
.bs-xs-90{height: 90px;}
.bs-xs-95{height: 95px;}
.bs-xs-100{height: 100px;}
.bs-xs-105{height: 105px;}
.bs-xs-110{height: 110px;}
.bs-xs-115{height: 115px;}
.bs-xs-120{height: 120px;}
.bs-xs-150{height: 150px;}

.mr-xs-20 {margin-right: 20px;}
.mr-xs-25 {margin-right: 25px;}
.mr-xs-30 {margin-right: 30px;}
.ml-xs-20 {margin-left: 20px;}
.ml-xs-25 {margin-left: 25px;}
.ml-xs-30 {margin-left: 30px;}
.pb-30 {padding-bottom: 30px;}

@media (max-width: 1200px) {
    .lg-15 {padding-bottom: 15px;}
}
@media (max-width: 992px) {
    .md-15 {padding-bottom: 15px;}
}
@media (max-width: 768px) {
    .sm-15 {padding-bottom: 15px;}
}
@media (max-width: 480px) {
    .xs-15 {padding-bottom: 15px;}
}


@media (min-width: 768px) {
    .bs-sm-0{height: 0px;}
    .bs-sm-5{height: 5px;}
    .bs-sm-10{height: 10px;}
    .bs-sm-15{height: 15px;}
    .bs-sm-20{height: 20px;}
    .bs-sm-25{height: 25px;}
    .bs-sm-30{height: 30px;}
    .bs-sm-35{height: 35px;}
    .bs-sm-40{height: 40px;}
    .bs-sm-45{height: 45px;}
    .bs-sm-50{height: 50px;}
    .bs-sm-55{height: 55px;}
    .bs-sm-60{height: 60px;}
    .bs-sm-65{height: 65px;}
    .bs-sm-70{height: 70px;}
    .bs-sm-75{height: 75px;}
    .bs-sm-80{height: 80px;}
    .bs-sm-85{height: 85px;}
    .bs-sm-90{height: 90px;}
    .bs-sm-95{height: 95px;}
    .bs-sm-100{height: 100px;}
    .bs-sm-105{height: 105px;}
    .bs-sm-110{height: 110px;}
    .bs-sm-115{height: 115px;}
    .bs-sm-120{height: 120px;}
    .bs-sm-150{height: 150px;}
    .text-sm-left {text-align: left;}
    .text-sm-right {text-align: right;}
    .justify-content-end-sm {justify-content: flex-end;}
}
@media (min-width: 992px) {
    .bs-md-0{height: 0px;}
    .bs-md-5{height: 5px;}
    .bs-md-10{height: 10px;}
    .bs-md-15{height: 15px;}
    .bs-md-20{height: 20px;}
    .bs-md-25{height: 25px;}
    .bs-md-30{height: 30px;}
    .bs-md-35{height: 35px;}
    .bs-md-40{height: 40px;}
    .bs-md-45{height: 45px;}
    .bs-md-50{height: 50px;}
    .bs-md-55{height: 55px;}
    .bs-md-60{height: 60px;}
    .bs-md-65{height: 65px;}
    .bs-md-70{height: 70px;}
    .bs-md-75{height: 75px;}
    .bs-md-80{height: 80px;}
    .bs-md-85{height: 85px;}
    .bs-md-90{height: 90px;}
    .bs-md-95{height: 95px;}
    .bs-md-100{height: 100px;}
    .bs-md-105{height: 105px;}
    .bs-md-110{height: 110px;}
    .bs-md-115{height: 115px;}
    .bs-md-120{height: 120px;}
    .bs-md-150{height: 150px;}
    .text-md-left {text-align: left;}
    .text-md-right {text-align: right;}
    .justify-content-end-md {justify-content: flex-end;}
}
@media (min-width: 1200px) {
    .bs-lg-0{height: 0px;}
    .bs-lg-5{height: 5px;}
    .bs-lg-10{height: 10px;}
    .bs-lg-15{height: 15px;}
    .bs-lg-20{height: 20px;}
    .bs-lg-25{height: 25px;}
    .bs-lg-30{height: 30px;}
    .bs-lg-35{height: 35px;}
    .bs-lg-40{height: 40px;}
    .bs-lg-45{height: 45px;}
    .bs-lg-50{height: 50px;}
    .bs-lg-55{height: 55px;}
    .bs-lg-60{height: 60px;}
    .bs-lg-65{height: 65px;}
    .bs-lg-70{height: 70px;}
    .bs-lg-75{height: 75px;}
    .bs-lg-80{height: 80px;}
    .bs-lg-85{height: 85px;}
    .bs-lg-90{height: 90px;}
    .bs-lg-95{height: 95px;}
    .bs-lg-100{height: 100px;}
    .bs-lg-105{height: 105px;}
    .bs-lg-110{height: 110px;}
    .bs-lg-115{height: 115px;}
    .bs-lg-120{height: 120px;}
    .bs-lg-150{height: 150px;}
    .text-lg-left {text-align: left;}
    .text-lg-right {text-align: right;}
    .text-lg-center {text-align: center;}
    .justify-content-end-lg {justify-content: flex-end;}
}