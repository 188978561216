@use '@angular/material' as mat;
@use 'variables' as *;
$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Poppins, sans-serif',
);
@include mat.all-component-typographies($custom-typography);

:root {
    --mat-datepicker-calendar-date-selected-state-background-color: #7FA3EE;
    --mat-datepicker-calendar-date-hover-state-background-color: #EFF2F7;
    --mdc-typography-body2-font-weight: 300;
    --mdc-typography-body2-letter-spacing: 0;
    --mdc-theme-text-primary-on-background: #3E4C58;
    --mdc-checkbox-selected-icon-color:#7FA3EE; 
    --mdc-checkbox-selected-hover-icon-color: #7FA3EE;
    --mdc-checkbox-selected-focus-icon-color: #7FA3EE;
    --mdc-checkbox-selected-active-icon-color: #7FA3EE;
    --mdc-checkbox-selected-pressed-icon-color: #7FA3EE;
    --mdc-checkbox-selected-hover-state-layer-color: #7FA3EE;
    --mdc-checkbox-selected-focus-state-layer-color: #7FA3EE;
    --mdc-checkbox-selected-active-state-layer-color: #7FA3EE;
    --mdc-checkbox-selected-pressed-state-layer-color: #7FA3EE;
    --mdc-circular-progress-active-indicator-color: #7FA3EE;
    --mat-slide-toggle-label-text-tracking: 0;
}
mat-radio-button {
    --mdc-radio-selected-icon-color: #252D41!important;
    --mdc-radio-selected-focus-icon-color: #252D41!important;
    --mdc-radio-selected-hover-icon-color: #252D41!important;
    --mdc-radio-selected-active-icon-color: #252D41!important;
    --mdc-radio-selected-pressed-icon-color: #252D41!important;
    --mdc-radio-selected-icon-color: #252D41!important;
    --mat-radio-checked-ripple-color:#252D41!important;
}
.cdk-global-overlay-wrapper {overflow-y: auto;}
.cdk-global-scrollblock {position: relative!important;}

// buttons, links
button {outline: none; background: none; border: none; cursor: pointer; position: relative; user-select: none; transition: var(--transition-text);}
.app-button {min-height: 40px; border-radius: $radius; padding: 12px 20px; font-size: 14px; letter-spacing: var(--letter-spacing); position: relative; overflow: hidden; font-weight: var(--weight-normal); display: inline-block; transition: var(--transition-text); white-space: nowrap; text-align: center;
    .icon {margin-right: 16px;}
    &:disabled {opacity: 0.6; cursor: default; pointer-events: none;}
}
.btn-lg {min-height: 54px; font-size: 16px; padding: 15px 24px;}
.btn-sm {min-height: 32px; height: 32px; font-size: 13px; line-height: 32px; padding: 0px 12px;letter-spacing: 0;}
.btn-full {width: 100%;}
.btn-input input {position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 1; cursor: pointer; opacity: 0;}
.btn-primary {background-color: $primary; color: #fff;
    &:hover {background-color: $lighter;}
}
.btn-secondary {background-color: $secondary; color: #fff;
    &:hover {background-color: $hover;}
}
.btn-invalid {background-color: $invalid; color: #fff;
    &:hover {background-color: $lighter;}
}
.btn-grey {background: $grey; color: $primary; border: 1px solid $grey;
    &:hover {background-color: #fff; border-color: $greyBg;}
}
.btn-white {background: #fff; color: $primary;
    &:hover {background-color: $secondary; color: #fff;
        mat-icon {color: #fff;}
    }
}
.btn-outlined {border: 1px solid $secondary; color: $primary; background-color: transparent;
    &:hover {background-color: $secondary; color: #fff;}
}
.btn-toggle {border: 1px solid $greyBg; text-align: left; display: flex; justify-content: space-between; align-items: center;
    span {font-size: 12px;}
    img {position: absolute; right: 10px; top: 50%; margin-top: -10px;}
    &.active {border-color: $secondary;}
    .icon-toggle {display: none;}
    &.active .icon-toggle {display: block;}
}
.btn-icon {height: 40px; width: 40px; border-radius: $radius; cursor: pointer; display: flex; align-items: center; justify-content: center; position: relative; text-align: center; padding: 0; flex-shrink: 0;
    &.sm {width: 30px; height: 30px; min-width: 30px;} 
    &.lg {width: 56px; height: 56px; min-width: 56px;} 
    img {display: block; margin: 0 auto; max-width: 100%;}
    .mat-icon {font-size: 24px; line-height: 24px;}
    &:disabled {opacity: 0.6; cursor: default; pointer-events: none;}
}
.btn-round {border-radius: 50%;}
.btn-left-align {text-align: left; padding: 0 24px;}
.btn-add {display: flex; align-items: center; min-height: 68px; padding: 24px; max-width: 313px; border: 1px solid $greyBg; border-radius: $radius; letter-spacing: 0.5px; cursor: pointer;
    img {margin-right: 16px;}
    p {font-size: 14px; font-weight: 400; line-height: 20px;}
}
.btn-simple {padding: 8px; cursor: pointer; width: 36px; height: 36px;
    img {max-width: 100%;}
}
.simple-link {font-size: 14px; font-weight: 400; transition: var(--transition-text);
    &:hover {color: $hover;}
}
.simple-link-hover > *, .link-hover {transition: var(--transition-text);}
.simple-link-hover:hover > *, .link-hover:hover, .link-hover:focus {color: $hover;}
.mat-button-toggle-disabled {opacity: 0.6; cursor: not-allowed;
     .mat-button-toggle-button {cursor: not-allowed!important;}
}

// form controls
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {color: $primary!important;}
.app-form-field.mat-mdc-form-field {width: 100%; font-size: 16px; font-weight: 300; letter-spacing: 0;
    &.disabled {opacity: 0.7; pointer-events: none; }
    .mdc-line-ripple, .mat-mdc-form-field-focus-overlay {display: none;}
    .mat-mdc-form-field-infix {min-height: 52px; padding-top: 20px!important; padding-bottom: 10px!important;}
    .mat-mdc-form-field-hint-wrapper, .mdc-text-field {padding: 0 20px; }
    .mat-mdc-form-field-error-wrapper {padding: 0px; font-size: 14px; letter-spacing: var(--letter-spacing);}
    .mdc-text-field {border-radius: $radius; background-color: #fff; border: 1px solid $greyBg;}
    .mdc-floating-label {font-size: 16px; font-weight: 300; letter-spacing: 0;}
    .mat-mdc-form-field-error, &.ng-invalid.ng-dirty .mdc-floating-label {color: $invalid!important;}
    &.ng-invalid.ng-touched .mdc-text-field {border-color: $invalid!important;}
    &:hover .mdc-text-field {border-color: $secondary;}
    &.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {padding-right: 0;}
    &.no-bottom-offset .mat-mdc-form-field-subscript-wrapper {display: none;}
}
.no-gutters .mat-mdc-form-field-subscript-wrapper {display: none;}
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label, .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above, .mat-mdc-option, .mat-mdc-select-value-text {color: $primary!important;}
.mat-mdc-select-value-text, .mat-mdc-option {font-size: 16px!important; font-weight: 300!important;letter-spacing: 0!important;}
.form-field-textarea.app-form-field.mat-mdc-form-field .mdc-text-field {padding-right: 10px;}
.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {transform: translateY(-5px); -webkit-transform: translateY(-5px);}
.mdc-label img {display: block;}
.no-margin .mat-mdc-form-field-subscript-wrapper {display: none;}
.mat-menu-item.mat-mdc-menu-panel {border-radius: $radius; border: 1px solid $greyBg; box-shadow: none; 
    &.menu-user-align {margin-left: 30px; margin-top: 12px;}
}
.mat-menu-item .mat-mdc-menu-item {min-height: 56px; }
.mat-menu-item .mat-mdc-menu-item .mat-mdc-menu-item-text, .mat-mdc-slide-toggle .mdc-label {font-size: 16px!important; color: $lighter!important; font-weight: 300!important;}
.divider-menu {margin: 8px 16px; height: 1px; display: block; background-color: $greyBg;}
.mat-mdc-radio-button {margin-right: 10px;}
.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {transform: translateY(-4px)!important;}
.mat-mdc-slide-toggle .mdc-label {margin-left: 16px;}
.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after, .mdc-switch:enabled .mdc-switch__track::after {background-color: $secondary!important;}

@media (max-width: #{992px}){
    .btn-lg {padding: 16px; font-size: 14px;}
}
@media (max-width: #{576px}){
    .app-form-field.mat-mdc-form-field .mat-mdc-form-field-hint-wrapper, .app-form-field.mat-mdc-form-field .mdc-text-field {padding: 0 10px;}
    .app-form-field.mat-mdc-form-field, .app-form-field.mat-mdc-form-field .mdc-floating-label, .mat-mdc-select-value-text, .mat-mdc-option {font-size: 14px!important;}
    .app-form-field.mat-mdc-form-field .mat-mdc-form-field-error-wrapper {font-size: 12px;}
    .btn-add {max-width: none;}
    .btn-full-mobile {width: 100%;}
}
