@use 'variables' as *;
@import 'bootstrap/dist/css/bootstrap-grid.min.css';
/* 300 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format('woff2');
}
/* 400 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
}
/* 500 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format('woff2');
}
/* 700 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format('woff2');
}
/* 900 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLBT5Z1xlFQ.woff2) format('woff2');
}

:root {
    --top-offset: 94px;
    --animated-index: 0;
    --transition-text: all .3s ease-in;
    --transition-type-1: all .5s cubic-bezier(.1, .6, .4, 1);
}
::-webkit-scrollbar { width: 10px;}
::-webkit-scrollbar-track {border-radius: 6px; background-color: $grey;}
::-webkit-scrollbar-thumb {background: $secondary;border-radius: 2px;}
.iframe-wrapper {position: relative; width: 100%; padding-bottom: 40%;
    video {width: 100%; height: 100%; display: block; position: absolute; left: 0; top: 0;}
}
.d-flex-align {display: flex; align-items: center; }
.d-flex-column {flex-direction: column;}
.full-width {width: 100%;}
.align-content {display: flex; align-items: center; justify-content: center; text-align: center;}
.wrapper {position: relative;}
.bg {position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-repeat: no-repeat; background-size: cover; background-position: center center;
   &.bg-banner-top {background-size: contain; background-position: left 200px; width: 1403px;height: 891px;} 
   &.bg-banner-bottom {background-size: contain; background-position: left 400px; width: 1403px;height: 891px;} 
}
.bg-wrapper {position: relative;overflow: hidden;}
.page-wrapper {max-width: 1430px; margin: 0 auto; position: relative; padding: 0 15px; width: 100%;}
.promo-offset {padding-top: 32px;
    .header {top: 32px!important;}
    .search-wrapper {margin-top: 24px;}
    .profile-menu .close-icon {top: 47px;}
    .submenu {margin-top: 32px;}
}
.full-height-page {min-height: calc(100vmin - var(--top-offset));}
.body-wrapper {padding-top: var(--top-offset); min-height: calc(100vmin - var(--top-offset));
    &.no-offset {padding-top: 0; min-height: 100vmin;}
}
.row {--bs-gutter-x: 30px;}
.row-xs {--bs-gutter-x: 10px;}
.row-lg {--bs-gutter-x: 60px;}
.row-lx {--bs-gutter-x: 120px;}
.row-lxx {--bs-gutter-x: 160px;}
.row-0 {--bs-gutter-x: 0px;}
.row-20 {--bs-gutter-x: 20px;}
app-root {overflow: hidden; display: block; width: 100%; position: relative;}
.blur-bg {position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-color: #FFFFFFCC; backdrop-filter: blur(10.5px); }
.img-full {display: block; max-width: 100%;}
.cdk-overlay-backdrop:not(.mat-datepicker-0-backdrop) {backdrop-filter: blur(10.5px);}
.img-wrapper {position: relative;overflow: hidden;width: 100%;padding-top: 100%;border-radius: $radius;
    picture img {position: absolute;top: 0;left: 0;width: 100%;height: 100%; max-width: 100%; object-fit: cover; -o-object-fit: cover; -webkit-backface-visibility: hidden; -ms-transform: translateZ(0);-webkit-transform: translateZ(0); backface-visibility: hidden;transform: translateZ(0); transform: rotate(0.00000000001deg);}
    &.img-lg {padding-top: 111%;}
    &.img-ratio {padding-top: 67%;}
    &.img-md {padding-top: 79%;}
    &.img-sm {padding-top: 40%;}
    &.img-half {padding-top: 50%;}
   
}
.img-wrapper-xs {width: 108px; height: 108px; min-width: 108px; padding: 0; border-radius: $radius; overflow: hidden;
    img {object-fit: cover; width: 100%; height: 100%;}
}
.number-item {min-width: 40px; height: 40px; color: #fff; background-color: $secondary; border-radius: 50%; text-align: center; line-height: 40px; font-size: 16px;}
.icon-dark-wrapper {background-color: $primary; border-radius: $radius; width: 80px; height: 80px; min-width: 80px; display: flex; align-items: center; justify-content: center;
    img {max-width: 100%; display: block;}
}
.image-hover .img-wrapper img, .image-hover.img-wrapper img, .image-hover .bg {transition: var(--transition-type-1); -webkit-transition: var(--transition-type-1); cursor: pointer;}
.image-hover:hover .img-wrapper img, .image-hover.img-wrapper:hover img, .picture-wrapper:hover img, .image-hover:hover .bg{transform: scale(1.1); -webkit-transform: scale(1.1);}
.image-text {transition: var(--transition-text); -webkit-transition: var(--transition-text);}
.image-text:hover {color: $secondary;}
.picture-wrapper {position: relative; overflow: hidden; backface-visibility: hidden; z-index: 1;}
.picture-wrapper img {max-width: 100%; margin: 0 auto; transition: var(--transition-type-1); -webkit-transition: var(--transition-type-1); cursor: pointer;}
.picture-wrapper picture {font-size: 0; display: block; text-align: center;}
.dialog-container .app-button, .min-width {min-width: 150px;}
.min-width-lg {min-width: 230px;}
.dialog-btn-align {text-align: center;
    .app-button {margin: 0 10px;}
}
.dialog-close {position: absolute; right: 0; top: 0; z-index: 2; cursor: pointer;}
.circle {position: absolute; border-radius: 50%; border: 1px solid #8AA6D6;}
.item-border {border: 1px solid $greyBg; border-radius: $radius; padding: 24px;
    &.sm {padding: 16px;}
    &.xs {padding: 12px;}
}
.item-simple {border-radius: $radius; padding: 24px; background-color: #fff; position: relative;
    &.sm {padding: 16px;}
    &.xs {padding: 12px;}
    .inner-loader .loading-wrapper {background-color: #fff; min-height: none; border-radius: $radius;}
}
.item-background {background-color: $greyBg;}
.msg-align {width: 85%;}
.item-shadow {box-shadow: 0px 4px 20px 0px #252D411A;}
.loading {cursor: progress;}
.loading-wrapper {background-color: #fff; position: fixed; width: 100%; height: 100%; left: 0; top:0; z-index: 4; display: none; align-items: center; justify-content: center;}
.inner-loader .loading-wrapper {position: absolute;}
.loading .loading-wrapper {display: flex; }
.mat-mdc-menu-content{padding: 0!important; }
.add-banner {position: fixed; left: 0; top:0; width: 100%; height: 32px; text-align: center; background-color: $primary; z-index: 1000; color: #fff; display: flex; justify-content: center; align-items: center; font-size: 14px; padding: 0 10px;
    a {position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 1; display: block;}
}
.init-loader {position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 10000; background-color: #fff; display: flex; align-items: center; justify-content: center;}
.custom-loader {width: 48px;height: 48px;border-radius: 50%;position: relative;animation: rotate 1s linear infinite;}
.custom-loader::before {content: "";box-sizing: border-box;position: absolute; inset: 0px;border-radius: 50%;border: 5px solid $secondary; animation: prixClipFix 2s linear infinite ;}
@keyframes rotate {
    100%   {transform: rotate(360deg)}
}
@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}
.hide-loader .init-loader {display: none;}
.full-screen-dialog {width: 100vw!important; max-width: 100vw!important; height: 100vh!important; max-height: 100vh!important; overflow: hidden!important;
    .mat-mdc-dialog-surface {background-color: #E5EEFF; overflow: auto;}
}

// tabs
.tab-header {width: 100%; white-space: nowrap; min-width: 290px; text-align: center; padding: 14px; font-size: 16px; font-weight: 400; border: 1px solid $grey; background-color: $grey; border-radius: $radius; margin-bottom: 10px; cursor: pointer;transition: var(--transition-type-1); -webkit-transition: var(--transition-type-1);
    &.active {background-color: #fff; border-color: $secondary;}
}
.tab-content { overflow: hidden; position: relative;}
.tab-content-wrapper { display: none; 
    &.active {display: block;}
}

// cookie
.cookies-popup {position: fixed; z-index: 1000; width: 406px; padding: 24px; bottom: 50px; left: 50px; border: 1px solid $greyBg; background: #fff; border-radius: $radius;}
.popup-close {position: absolute; right: 10px; top: 10px; z-index: 2; width: 20px; height: 20px; cursor: pointer;
    svg {transition: var(--transition-text);}
    &:hover svg {color: $hover;}
}

// response message
.header-message{position: fixed; top: 0; left: 0; right: 0; line-height: 16px; z-index: 1001; background: #FFF;
  div{min-height: 35px;}
}
.sub-menu-link {transition: var(--transition-text); cursor: pointer; color: $primary; display: flex; align-items: center; padding: 35px 14px; text-transform: uppercase; font-weight: 400; font-size: 13px; letter-spacing: var(--letter-spacing); 
    .mat-icon {font-size: 18px; width: 18px; height: 18px; margin-left: 10px; line-height: 18px;}
    .c-icon {display: none;}
    &.active {color: $secondary;}
}
.active .sub-menu-link .c-icon, .active app-sub-menu {display: block;} 
.active .sub-menu-link .o-icon {display: none;} 

//slider
swiper-container:not(:allow-touch-move) {cursor: grab;}
.slider-navigation { position: relative; padding: 0 64px;}
swiper-container.with-pagination::part(container) {padding-bottom: 33px;}
swiper-container::part(pagination) {bottom: -3px!important;}
swiper-container::part(bullet) {width: 16px; height: 3px; border-radius: 0; background-color: $grey; opacity: 1;}
swiper-container::part(bullet-active) {width: 16px; height: 3px; border-radius: 0; background-color: $secondary;}
.slider-nav {position: absolute; top: 50%; width: 36px; height: 36px; cursor: pointer; z-index: 1; margin-top: -34px;
    &.disabled {opacity: 0.3;}
}
.slider-nav-prev {left: 0;}
.slider-nav-next {right: 0;}
.full-width-container swiper-slide {width: auto;}
swiper-container.full-width-container::part(container) {overflow: visible;}

// banners
app-header {z-index: 6; position: relative;}
.main-banner {position: relative; padding-top: 55px;}
.main-banner-wrapper {display: flex; align-items: center; min-height: 630px;}
.banner-col-left, .banner-col-right {position: relative; height: 100%;}
.banner-col-left {width: 55%;}
.banner-col-right {width: 45%;}
.banner-col-right img {display: block; max-width: 100%; height: auto;}
.img-label-wrapper {position: relative; height: 100%;}
.main-banenr-text {opacity: 0; transition: var(--transition-type-1);
    &.active {opacity: 1;}
}
.main-banenr-hover-text { position: absolute; left: 0; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); opacity: 0; visibility: hidden; transition: var(--transition-type-1); -webkit-transition: var(--transition-type-1);
    .banner-title, .banenr-hover-desc {opacity: 0; transition: var(--transition-type-1); -webkit-transition: var(--transition-type-1);}
    .banner-title {transform: translateY(25px); -webkit-transform: translateY(25px);}
    .banenr-hover-desc {transform: translateY(35px); -webkit-transform: translateY(35px); transition-delay: 0.15s; -webkit-transition-delay: 0.15s;}
    &.active {opacity: 1; visibility: visible;
       .banner-title, .banenr-hover-desc {opacity: 1; transform: translateY(0); -webkit-transform: translateY(0);}     
    }
}
.banenr-hover-desc {font-size: 16px; line-height: 24px; padding-right: 132px;
    p {margin: 0 0 8px 0;}
    a {font-weight: var(--weight-semi); color: $primary;
        &:hover {color: $secondary;}
    }
}
.header-background-offset {position: fixed; left: 0;  width: 100%; top: 0; height: var(--top-offset);}
.banner-video-wrapper {position: relative; width: 100%; height: calc(100vh - var(--top-offset)); overflow: hidden; display: flex; align-items: center;}
.no-offset .banner-video-wrapper {height: 100vh;}
.promo-offset {
    .header-background-offset {height: calc(var(--top-offset) + 32px);}
    .banner-video-wrapper {height: calc(100vh - var(--top-offset) - 32px);}
    .no-offset .banner-video-wrapper {height: calc(100vh - 32px);}
}
// top: calc(var(--top-offset) * -1);
.main-banner-label {position: absolute;  top: -55px; width: 168px; height: 168px; display: flex; flex-shrink: 0; align-items: center; justify-content: center; background-color: $secondary; border-radius: 50%; font-size: 16px; text-align: center; line-height: 20px; font-weight: var(--weight-semi); flex-direction: column; padding: 5px;
    & * {font-family: "Poppins", sans-serif!important;}
    h3 {font-weight: 700; color: #fff; font-size: 130%; line-height: 153%; display: block;}
    h2 {font-weight: 700; color: #fff; font-size: 160%; line-height: 130%; display: block;}
    p {display: block; width: 100%;}
    &.center {left: 50%; margin-left: -84px;}
    &.right {right: 0;}
    &.left {left: 0;}
}
.main-banner-image-bg {
    &.center img {margin: 0 auto;}
    &.left img {margin: 0 auto 0 0;} 
    &.right img {margin: 0 0 0 auto;} 
}
.main-banner-image-product {position: absolute;  bottom: -74px; z-index: 2;
    &.center {left: 50%; transform: translateX(-50%);}
    &.right {right: 0px;}
    &.left {left: 0px;}
}

.page-banner {
    .banner-col-left {width: 56.5%;}
    .banner-col-right {width: 43.5%;}
}
.page-banner-1 {position: relative; height: 100%;
    .img-label-wrapper .benefit-0 {right: 20px; top: -50px;}
    .img-label-wrapper .benefit-1 {left: -20px; bottom: 50px;}
}
.page-banner-2 {position: relative; height: 100%;
    .img-label-wrapper .benefit-0 {left: 45px; bottom: 157px;}
    .img-label-wrapper .benefit-1 {left: -20px; bottom: 50px;}
}
.page-banner-3 {position: relative; height: 100%;
    .img-label-wrapper .benefit-0 {left: -20px; bottom: 50px;}
}

// home page
app-banner, app-page-banner {position: relative; display: block;}
.bg-main-banner {background-size: contain; background-position: left 0 top 0;}
.blur-layer {background: #F2F2F299; backdrop-filter: blur(4px); border-radius: $radius; overflow: hidden;}
.banner-link-wrapper { padding-top: 10px;
  .banner-link {cursor: pointer;}  
}
.banner-link {position: relative; padding: 24px; display: flex; width: 100%; height: 100%; background-color: #fff; border-radius: $radius; transition: var(--transition-type-1); border: 1px solid $secondary;
    .banner-link-title {width: 78px; height: 78px; display: flex; align-items: center; justify-content: center; color: #fff; border-radius: 50%; background-color: #B2C8F5; flex-shrink: 0; position: relative;
        &:after {content: ''; position: absolute; left: 9px; top: 9px; right: 9px; bottom: 9px; background-color: $secondary; border-radius: 50%; transition: var(--transition-type-1);}
        &:before {content: ''; position: absolute; left: 29px; top: 29px; right: 29px; bottom: 29px; background-color: #fff; border-radius: 50%; transition: var(--transition-type-1); opacity: 0;}
        p {position: relative; z-index: 1; font-size: 16px; font-weight: 600; transition: var(--transition-type-1);}
    }
    .banner-link-text {font-size: 16px; line-height: 26px; font-weight: 400; padding-left: 16px;}
    &:hover {background-color: $secondary;
        .banner-link-title {background-color: rgba(255,255,255,0.6);  
            p {color: $secondary;}
        }
        .banner-link-title:after {opacity: 0;}
        .banner-link-title:before {opacity: 1; left: 9px; top: 9px; right: 9px; bottom: 9px;}
        .banner-link-text {color: #fff;}
    }
}
.banner-products-wrapper {display: flex; flex-direction: row; position: relative; z-index: 3; margin-left: -5px; margin-right: -5px;}
.banner-product-col {min-width: 218px; padding: 0 5px; flex: 0 0 auto;max-width: 218px;}
.banner-product {position: relative; width: 100%; background: #F2F2F299; padding: 16px; height: 100%; display: flex; justify-content: space-between; flex-direction: column;
    .line-1, .line-2 {width: 100%; height: 2px;}
    .line-1 {background-color: $secondary;}
    .line-2 {background-color: $greyBg;}
    ul { padding: 0; margin: 0;}
    ul li {margin-bottom: 6px; display: block; position: relative; padding-left: 16px; font-size: 14px; line-height: 20px;
        &:after {content: ''; position: absolute; left: 0; top: 8px; width: 5px; height: 5px; border-radius: 50%; background-color: $secondary;}
    }
}
.banner-description-list ul {list-style: none; padding: 0; display: flex; flex-wrap: wrap;
    li {font-size: 20px; line-height: 26px; margin-bottom: 8px; padding: 9px 0 9px 50px; position: relative; min-width: 50%; font-weight: var(--weight-semi);
        &:before {content:''; position: absolute; left: 0; top: 50%; margin-top: -13px; width: 26px; height: 26px; background-color: $secondary; border-radius: 50%;}
        &:after {content: ''; position: absolute; left: 7px; top: 50%; margin-top: -4.5px; width: 12px; height: 9px; background-image: url(./assets/icons/check-white.svg); background-repeat: no-repeat; background-size: cover; z-index: 1;}
    }
}
.review-placeholder {display: flex; width: 100%; align-items: center; justify-content: center; height: 204px; flex-direction: column;}
.review-star { display: flex; align-items: center; justify-content: end; flex-direction: row;
    img {margin-left: 2px;}
}
.review-item {background-color: $grey; border-radius: $radius; padding: 32px; min-height: 290px; justify-content: space-between;display: flex;flex-direction: column;}
.category-item { padding: 12px; display: flex; align-items: end; position: relative; height: 360px; overflow: hidden; border-radius: $radius;} 
.category-item-title {padding: 14px 16px 18px; display: flex; align-items: center;position: relative;width: 100%;justify-content: space-between;
    h4 {position: relative;}
}
.test-item {position: relative; border: 1px solid $greyBg; background-color: #fff; padding: 16px; border-radius: $radius; min-height: 484px; display: flex; justify-content: space-between; flex-direction: column;
    .img-wrapper {height: 220px; padding-top: 0; max-width: 220px; margin: 0 auto;}
}
.test-item-title {background-color: $grey; border-radius: $radius; padding: 12px; min-height: 68px;}
.product-label { color: $primary; text-transform: uppercase; font-weight: 500; border-radius: $radius; padding: 4px 8px; display: inline-block; font-size: 12px;
    &.bg-1 {background: #FFD7004D;}
    &.bg-2 {background: #D68ACC4D;}
    &.bg-3 {background: #8ACCD64D;}
    &.bg-4 {background: #D6948A4D;}
}
.review-slider-nav {position: absolute; bottom: 0; right: 0; z-index: 3; display: flex; align-items: center;}
.review-slider-btn {cursor: pointer; display: flex; align-items: center;
    &.disabled {opacity: 0.3;}
}
.slider-count {display: flex; align-items: center; font-weight: 400;
    .current-slide {font-size: 22px; color: #fff;}
    .hash {font-size: 22px; color: $lighter;}
    .all-slides {font-size: 16px; color: $greyBg;}
}
.benefits {position: relative; width: 960px; height: 950px; margin: 0 auto;
    .benefit-0 {left: -20%;top: 10%;}
    .benefit-1 {left: 1%;top: 37%;}
    .benefit-2 {left: -16%; top: 63%;}
    .benefit-3 {right: -4%;top: 14%;}
    .benefit-4 {right: -2%;top: 48%;}
    .benefit-5 {right: -13%;top: 68%;}
    .circle-1 {width: 100%; height: 100%; opacity: 0.15;}
    .circle-2 {width: 86%; height: 86%; border-radius: 50%; left: 7%; top: 7%; border: 1px solid #8AA6D6; opacity: 0.3;}
    .circle-3 {width: 68%; height: 68%; border-radius: 50%; left: 16%; top: 16%; border: 1px solid #8AA6D6; opacity: 0.5;}
    .circle-4 {width: 40%; height: 40%; border-radius: 50%; left: 30%; top: 30%; box-shadow: 0px 0px 13.100000381469727px 0px $primary inset; box-shadow: 0px 0px 15px 0px #8AA6D64D; background-color: $secondary; font-size: 76px; font-weight: 500; color: #fff; text-align: center; display: flex; align-items: center; justify-content: center;}
}
.benefit-circle-wrapper {width: 100%; height: 100%; position: absolute; left: 0; top:0;}
.benefit-item {position: absolute; z-index: 1;}
.benefit-item-wrapper {position: relative; padding: 8px 16px 8px 8px; display: flex; align-items: center; width: 319px;}
.benefit-item-image {width: 70px; height: 70px; min-width: 70px; border-radius: $radius; overflow: hidden; position: relative; z-index: 1; background-color: $greyBg;}
.how-it-work-item {background-color: $secondary; border-radius: $radius; padding: 24px; min-height: 316px; display: flex; justify-content: space-between; flex-direction: column;
    .icon {width: 70px; height: 70px; border-radius: 4px; background-color: rgba(239, 242, 247, 0.3); display: flex; align-items: center; justify-content: center; min-width: 70px; 
        img {display: block; max-width: 100%;}
    }
    .title {color: #fff;}
    p {color: $whiteLighter;}
}
.offer-item {display: flex; justify-content: space-between; flex-direction: column; height: 100%;}
.ceo-content {overflow: hidden; position: relative;
    p {margin-bottom: 20px;}
}

.bg-icon {border-radius: $radius; width: 70px; height: 70px; display: flex; min-width: 70px; align-items: center; justify-content: center;
   img {max-width: 100%; display: block;}
}
.text-bg-list {border-radius: $radius; padding: 24px; font-size: var(--text-size); line-height: var(--text-height); background-color: $grey; color: $primary;} 

// test page
.works-item {border-radius: $radius; border: 1px solid $greyBg; padding: 24px; min-height: 445px; display: flex; width: 100%; justify-content: space-between; flex-direction: column;
    .number-item {margin-left: 10px;}
    &.simple {min-height: 255px; justify-content: flex-start;}
}
.two-img-wrap {display: flex;
    .img-wrapper {padding-top: 50%; margin: 0 4px;}
}
.logo-item-row {display: flex; flex-wrap: wrap; margin-left: -5px; margin-right: -5px;}
.logo-item-col {width: 20%; padding: 0 5px 10px; flex: 0 0 auto; display: flex;}
.logo-item {border-radius: $radius; min-height: 130px; border: 1px solid $greyBg; display: flex; align-items: center; justify-content: center; width: 100%;
    img {display: block; max-width: 100%;}
}
.bg-text-item {position: relative; padding: 60px 80px; overflow: hidden; background-color: $secondary; border-radius: $radius;}
.image-label {position: relative;
    .benefit-item {top: 30px; left: -150px; z-index: 1;}
    &.reverse .benefit-item {left: auto; right: -150px;}
}
.page-banner-label {
    .benefit-item:nth-child(1) {top: -50px; left: auto; right: 22px;}
    .benefit-item:nth-child(2) {top: auto; bottom: 50px; left: -22px;}
}
.password-hint {display: flex; align-items: flex-start; padding-right: 20px; 
    img {margin-right: 8px;}
}

//about page
.form-wrapper {position: relative; padding: 60px 80px; background: #F2F2F299;}
.feature-item {position: relative; border: 1px solid $greyBg; border-radius: $radius; padding: 24px; min-height: 315px; height: 100%;} 
.number-text {font-size: 80px; line-height: 80px; color: $secondary; font-weight: 400;}
.bg-primary {
    .feature-item {border-color: #FFFFFF26;
        .divider {background-color:#FFFFFF26;}
        h5 {color: #fff;}
        p {color: rgba(255,255,255, 0.6);}
    }
}
.simple-item {
    .content {padding: 14px 16px; margin-top: -15px; z-index: 1; position: relative;}
}
.arch-wrapper {position: relative; overflow: hidden;
    &:after {content: ''; position: absolute; left: 0; bottom: 0; width: 100%; height: 87px;background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);}
    .arch-item-offset {margin-top: 110px;}
    .arch-circle { width: 950px; height: 950px; position: absolute; bottom: -475px; left: 50%; margin-left: -475px;
       .circle-1 {opacity: 0.15; width: 100%; height: 100%; top: 0;} 
       .circle-2 {opacity: 0.3; width: 80%; height: 80%; left: 10%; top: 10%;}
       .circle-3 {opacity: 0.5; width: 60%; height: 60%; left: 20%; top: 20%;}
       .circle-4 {width: 40%; height: 40%; left: 30%; top: 30%;}
    }
}
.arch-btn-align {text-align: center;}

//test detail
.works-item-number {width: 100px; height: 100px; border-radius: 50%; background-color: #F2F2F299; text-align: center; backdrop-filter: blur(10.5px); font-size: 26px; line-height: 100px; font-weight: 400; color: $secondary;
    span {position: relative; z-index: 2;}
    &:after {content: ''; width: 70%; height: 70%; background:rgba(255,255,255,0.3); left: 15%; top: 15%; border-radius: 50%; position: absolute;}
}
.works-item-test {position: relative; padding-top: 50px;
   .works-item-number {position: absolute; left: 50%; margin-left: -50px; top: 0px; z-index: 1; background-color: #FFFFFF99;
        &:after {background:#FFFFFF99;}
   }
   .works-item-title {position: absolute; left: 16px; bottom: 16px; right: 16px; border-radius: $radius; padding: 14px; text-align: center; cursor: pointer;}
}
.benefit-test {padding: 24px;}
.range-price-item {width: 100%; display: flex; flex-direction: column; position: relative; overflow: hidden; cursor: pointer;
    .range-row, .range-header {text-align: center; height: 78px; align-items: center; display: flex; justify-content: center; position: relative; flex-direction: column;
        &.row-text {height: auto; padding: 16px;}
    }
    .range-row  {border-bottom: 1px solid $greyBg; 
        .title {display: block;}
    }
    .range-header:not(.no-bg) {background-color: $grey; position: relative; transition: all 0.3s ease-in; -webkit-transition: all 0.3s ease-in;
        h4 {position: relative; z-index: 1;}
    }
    &.range-price-option .range-row, &.range-price-option .range-header  {align-items: flex-start; padding: 0 24px;}
    .range-row-price {display: flex; position: relative; justify-content: center;  flex-direction: column; padding: 24px 50px; text-align: center; z-index: 4;
        .simple-link-hover {justify-content: center; opacity: 0; transition: all 0.3s ease-in; -webkit-transition: all 0.3s ease-in;}
    }
    &:after {content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; border: 3px solid $secondary; border-radius: $radius; opacity: 0; z-index: 2; transition: all 0.3s ease-in; -webkit-transition: all 0.3s ease-in;}
    .range-header:after {background-color: $secondary; border-radius: $radius; content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 0; transition: all 0.3s ease-in; -webkit-transition: all 0.3s ease-in;}
    &.active .range-header h4, &:not(.range-price-option):hover .range-header h4 {color: #fff;}
    &.active:after, &.active .range-header:after, &.active:after, &.active .range-header:after, &:not(.range-price-option):hover:after, &:not(.range-price-option):hover .range-header:after, &:not(.range-price-option):hover:after, &:not(.range-price-option):hover .range-header:after {opacity: 1;}
    &:hover .range-row-price .simple-link-hover {opacity: 1;}
}
.option-text {display: none; margin-bottom: 10px; width: 100%;}
.no-option-range .range-price-item {height: 100%; justify-content: space-between; border-radius: $radius;
    &:after {border-color: $grey; opacity: 1;}
    .range-header {border-radius: $radius; }
    .range-row {text-align: left; border-bottom: 0 none;}
    .range-row-price {border-top: 1px solid $grey;}
    &.active:after, &.active .range-header:after {opacity: 1;}
    &:hover:after, &.active:after {border-color: $secondary;}
}

//blog
.blog-wrapper {max-width: 960px; padding: 0 15px; margin: 0 auto; position: relative;}
.btn-wrapper .app-button {margin: 0 10px 10px 0;}
.btn-wrapper .btn-outlined:hover {background-color: #fff; color: $primary;}
.article-content {font-size: var(--text-lg-size); line-height: var(--text-lg-height); font-weight: 300; font-family: "Poppins", sans-serif!important;
    a {color: $secondary; text-decoration: underline;}
    p {margin-bottom: 24px;}
    ul { margin-bottom: 25px;}
    ul li {margin-bottom: 10px; display: block; position: relative; padding-left: 25px;
        &:after {content: ''; position: absolute; left: 0; top: 10px; width: 5px; height: 5px; border-radius: 50%; background-color: $secondary;}
        P {margin-bottom: 0;}
        &:last-child {margin-bottom: 0;}
    }
    ul.text-sm li {font-size: 16px; line-height: 24px;}
    ol {list-style: none; counter-reset: li;}
    ol li::before {content: counter(li); color: $secondary; position: absolute; left: 0; top: 0; width: 20px; z-index: 1;}
    ol li {counter-increment: li; margin-bottom: 10px; padding-left: 20px; position: relative;}
    ul, ol {display: inline-block; width: 100%; list-style-position: inside; padding-left: 0px; margin-bottom: 25px;}
    h1, h2, h3, h4, h5, h6{margin: 0; font-weight: var(--weight-normal); color: $primary; margin-bottom: 25px;}
    h1{font-size: var(--h1-size); line-height: var(--h1-height);}
    h2{font-size: var(--h2-size); line-height: var(--h2-height);}
    h3{font-size: var(--h3-size); line-height: var(--h3-height);}
    h4{font-size: var(--h4-size); line-height: var(--h4-height);}
    h5{font-size: var(--h5-size); line-height: var(--h5-height);}
    h6{font-size: var(--h6-size); line-height: var(--h6-height);}
    img {width: 100%; height: auto; display: block; max-width: 100%; margin-bottom: 50px; border-radius: $radius;}
    iframe, video {border: 0 none; outline: none;}
    blockquote {font-size: 26px; line-height: 34px; font-weight: 400; color: $primary; padding-left: 110px; margin-bottom: 50px; position: relative; display: inline-block; width: 100%; margin-top: 20px;
        &:after {content: ''; position: absolute; left: 0; top: 0; background-repeat: no-repeat; background-image: url(assets/icons/quote.svg); width: 51px; height: 50px;}
    }
    table {width: 100%; margin-bottom: 50px!important; border: 0 none; border-spacing: 0!important;
        tr {border: 0 none!important;}
        tr:first-child td {background-color: $secondary; color: #fff; height: 78px!important; font-size: 22px; border:0 none; font-weight: 400; text-align: left; padding: 0 24px;
            &:first-child {border-top-left-radius: $radius; border-bottom-left-radius: $radius;}
            &:last-child {border-top-right-radius: $radius; border-bottom-right-radius: $radius;}
        }
        tr td {height: 78px!important; border-bottom: 1px solid $greyBg; padding: 0 24px; text-align: left; font-size: 18px; color: $primary; border-left: 0; border-right: 0; border-top: 0;
            img {max-width: 30px; margin: 0;}
            p {margin-bottom: 0; display: inline-block;}
        }
    }
    swiper-slide img {margin-bottom: 0px;}
    swiper-container {margin-bottom: 50px;}
    iframe {position: relative; width: 100%; height: 568px; overflow: hidden; border-radius: $radius;}
}
.bg-inner-content p {font-size: var(--text-lg-size); line-height: var(--text-lg-height); color: $primary; 
    a {font-weight: var(--weight-normal); text-decoration: underline;}
}
.ngx-pagination {display: flex; align-items: center; justify-content: center; margin-bottom: 0!important; padding: 0px;}
.ngx-pagination li {width: 48px; height: 48px; display: flex!important; min-width: 48px; align-items: center; justify-content: center; position: relative;}
.ngx-pagination li.small-screen {display: none!important;}
.ngx-pagination li a {display: block; width: 100%; height: 100%; font-size: 16px; line-height: 46px; text-align: center; padding: 0px; color: $primary; position: relative; z-index: 1;}
.ngx-pagination li {border-radius: $radius!important; border: 1px solid $greyBg; margin: 0 6px;}
.ngx-pagination li.current, .ngx-pagination li:hover {background-color: #fff; border-color: $secondary; color: $primary;}
.ngx-pagination a:hover, .ngx-pagination button:hover {background-color: transparent!important;}
.ngx-pagination .pagination-previous a:before, .ngx-pagination .pagination-previous.disabled:before, .ngx-pagination .pagination-next a:after, .ngx-pagination .pagination-next.disabled:after  {content: ''!important; margin: 0!important; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background-repeat: no-repeat; }
.ngx-pagination .pagination-next a:after, .ngx-pagination .pagination-next.disabled:after {background-image: url(assets/icons/arrow-next.svg); background-position: 100% center;}
.ngx-pagination .pagination-previous a:before, .ngx-pagination .pagination-previous.disabled:before {background-image: url(assets/icons/arrow-prev.svg); background-position: 0 center;}
.ngx-pagination .pagination-next.disabled:after, .ngx-pagination .pagination-previous.disabled:before {opacity: 0.3;} 
.ngx-pagination .pagination-previous, .ngx-pagination .pagination-next {border: 0 none!important;}
.blog-main-content .page-wrapper {max-width: none; padding: 0;}

//promo
.slider-auto-width {width: 360px;}
.promo-bg {background-color: $hover;
        .title {color: #fff;}
        .description {color: $whiteLighter;}
        .promo-banner-content {
            .app-button.btn-secondary {background-color: $primary;
                &:hover {background-color: #fff; color: $primary;}
            }
            .app-button.btn-outlined {border-color: #fff; color: #fff;
                &:hover {background-color: $primary; color: #fff; border-color: $primary;}
            }
        } 
}
.fixed .fixed-element {position: fixed; top: var(--top-offset);}

// product detail 
.product-img {width: 87px; margin-right: 10px;
    img {display: block; max-width: 100%;}
}
.warning-item {background: rgba(214, 186, 138, 0.2); font-size: 16px; min-height: 40px; padding: 8px 24px; border-radius: $radius; display: flex; align-items: center; justify-content: center; color: $primary;
    .icon {margin-right: 16px;}
}
.btn-product {display: flex; align-items: center; background-color: #8AD694; color: $primary; justify-content: center;
    &:hover {background-color: $primary; color: #fff;}
}
.product-banner {position: relative; overflow: hidden;
    .bg {background-position: left bottom; width: 825px; height: 525px; top: auto; bottom: 0;}
}
.item-icon {background-color: $grey; border-radius: $radius; display: flex; align-items: center; padding: 8px;
    .icon {width: 50px; height: 50px; min-width: 50px; border-radius: $radius; background-color: $greyBg; display: flex; align-items: center; justify-content: center; margin-right: 16px; 
        img {max-width: 100%; display: block;}
    }
}
.product-slider{display: flex;}
.product-thumbs {width: 87px;
    swiper-container {height: 375px!important;}
}
.product-preview {width: calc(100% - 87px); position: relative;
 swiper-container {height: 100%;}
}
.thumbs-item {width: 87px; height: 87px; position: relative; overflow: hidden; display: flex; align-items: center; justify-content: center; border-radius: 5px; background-color: $grey; border: 1px solid transparent; cursor: pointer;
    img {max-width: 100%; display: block;}
}
.swiper-slide-thumb-active .thumbs-item {background-color: transparent; border-color: $secondary;}
.product-preview-item {width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;
    img {display: block; max-width: 100%;}
}
.label-wraapper {position: absolute; right: 0; top: 0; z-index: 2; text-align: right;}
.select-simple { display: flex; align-items: center;
    .mat-mdc-select {width: auto;}
    p {margin-right: 12px;}
    mat-select .mat-mdc-select-value-text, p {font-size: 14px!important; font-weight: 400!important; text-transform: uppercase; color: $primary;}
    .mat-mdc-select-placeholder {color: $primary; text-transform: uppercase;}
    .mat-mdc-select-arrow {background-image: url(assets/icons/chevron-down.svg); width: 16px; height: 16px; margin-left: 12px;}
    .mat-mdc-select-arrow svg {display: none;}
}
.select-simple-options.mat-mdc-select-panel {min-width: 250px;
    .mat-mdc-option {font-size: 14px!important; font-weight: 400!important; text-transform: uppercase;}
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {color: $secondary;}
}
mat-option mat-pseudo-checkbox, .mat-button-toggle-focus-overlay, .mat-button-toggle-ripple {display: none!important;}
.filled {display: none;}
.outline {display: block;}
.active {
    .filled {display: block;}
    .outline {display: none;}
}
app-review .mat-pseudo-checkbox {display: none;}
.review-btns {display: flex!important; }
.review-btns, .review-btns .mat-button-toggle{background-color: transparent!important; border: 0 none!important;}
.review-btns .mat-button-toggle {width: 32px; height: 32px;}
.review-btns .mat-button-toggle-label-content {padding: 0px!important;}
.review-btns .mat-button-toggle-button {display: flex; align-items: center; justify-content: center; height: 100%;}
.promo-banner {position: relative; padding: 152px 0 128px; min-height: 100vh;}
.promo-banner-image {width: 100%; height: 100%; position: relative; overflow: hidden; 
    img {display: block; margin: 0 auto; width: 100%; height: auto; border-radius: $radius;}
}
.promo-col-left {width: 34.5%;}
.promo-col-right {width: 65.5%;}
.promo-banner-content {
    .app-button {min-width: 230px;}
}
.bg-promo-align.bg {width: 85%; height: 85%; background-size: contain; background-position: 0 429px;}

@media (min-width: #{1200px}){
    .promo-banner-content {padding-left: 113px;}
}
@media (max-width: #{1500px}){  
    .bg-promo-align.bg {width: 100%; height: 100%; background-position: 0 140px;}
    .promo-banner {padding-top: 130px; min-height: auto;}
    .promo-col-left {width: 22%;}
}
@media (max-width: #{1200px}){ 
    .promo-banner {padding: 100px 0 50px;}
    .promo-col-left {width: 34.5%;}
    .promo-offset .submenu {margin-top: 0;}
}
@media (max-width: #{768px}){
    .promo-col-left, .promo-col-right {width: 100%;} 
}
@media (max-width: #{480px}){
    .promo-banner {padding: 80px 0 40px;}
    .promo-banner-image img {max-width: 80%;}
}

//survey
.button-top {width: 50px; height: 50px;
    position: fixed; right: 90px; bottom: 20px; z-index: 10;
}
.question-content {max-width: 690px; margin: 0 auto; width: 100%;
    &.lg {max-width: 920px;}
    &.full {max-width: none;} 
}
.simple-header {position: fixed; left: 0; top: 0; width: 100%; height: 72px; background-color: #fff; box-shadow: 0px 4px 20px 0px rgba(37, 45, 65, 0.1); z-index: 10; padding: 16px 90px; display: flex; align-items: center; justify-content: space-between;}
.survey-footer {position: fixed; left: 0; bottom: 0; width: 100%; height: 86px; background-color: #fff; box-shadow: 0px 4px 20px 0px rgba(37, 45, 65, 0.1); z-index: 9; padding: 16px;}
.simple-page-content {min-height: 100vh; padding: 72px 16px 86px; display: flex; flex-direction: column; justify-content: space-between; height: 100%; position: relative;
    &.no-bottom {padding-bottom: 0px;}
    .page-wrapper {padding: 0px;}
    .loading-wrapper {background-color: $grey; min-height: 300px;}
}
.question-item {display: block; background-color: #fff; width: 100%; border-radius: $radius; border: 1px solid $greyBg; min-height: 52px; padding: 14px 50px 14px 20px; text-align: left; font-size: 16px; color: $input; margin-bottom: 20px; position: relative; cursor: pointer; transition: all 0.3s ease; -webkit-transition: all 0.3s ease;
    &:after {content: ''; position: absolute; width: 20px; height: 20px; right: 20px; top: calc(50% - 10px); background-image: url(./assets/icons/check-secondary.svg); background-repeat: no-repeat; opacity: 0; transition: all 0.3s ease; -webkit-transition: all 0.3s ease;}
    &.active, &:hover {border-color: $secondary; background-color: #8AA6D626; }
    &.active:after, &:hover:after {opacity: 1;}
    &.disabled {opacity: 0.6; cursor: not-allowed; pointer-events: none;}
}
.circle-img {position: relative; width: 160px; height: 160px; border-radius: 50%; margin: 0 auto 25px; overflow: hidden;}
.btns-toggle.mat-button-toggle-group-appearance-standard {border: 0 none; flex-direction: column; width: 100%;
    .mat-button-toggle {height: 40px; border: 1px solid $grey!important; background-color: $grey; color: $primary; width: 100%; border-radius: $radius; margin-bottom: 10px; display: block;
        &.mat-button-toggle-checked {border-color: $secondary!important; background-color: #fff;}
        .mat-button-toggle-label-content {font-size: 14px; line-height: 40px;}
    }
}
.selectable-product { cursor: pointer;  height: 100%;
    .test-item{background-color: #fff;  height: 100%;}
    &.active .test-item {border-color: $secondary;}  
}
.quiz-header {display: flex; justify-content: space-between; width: 100%;}
.profile-quiz {
    .simple-header {display: none;}
    .simple-page-content {min-height: auto;}
    .survey-footer {display: flex; z-index: 20; justify-content: flex-end; padding: 16px 50px;}
    .survey-footer .survey-footer-btns {max-width: calc(100% - 305px); width: 100%; margin: 0;}
    .simple-page-content {padding: 0px 16px 20px;}
}
.credit-card-wrapper {background-color: #fff; padding: 15px 19px 0 19px; border-radius: $radius;
    &.active {background-color: #8AA6D626;}
}

app-plan-item {display: block;}
.plan-item {background-color: #fff; height: 100%; display: flex; justify-content: space-between; flex-direction: column; margin-bottom: 30px;}
.active .plan-item {border-color: $secondary;}
.plan-content {max-width: 1168px; margin: 0 auto;}
.plan-item-header {background-color: rgba(242, 242, 242, 0.6); border-radius: $radius; padding: 12px; display: flex;align-items: center;
    .img-wrapper-xs {margin-right: 12px;}
}
.radio-item {padding: 8px; border-radius: $radius; border: 1px solid $secondary; min-height: 58px; flex-direction: column; justify-content: center; display: flex;
    &.active {background-color: rgba(138, 166, 214, 0.15);}
}
.promo-price-description strong {font-weight: var(--weight-semi);}
.step-html-content .article-content {text-align: left; width: 100%;
    ul {max-width: none!important; margin: 0 0 20px 0!important; list-style:outside!important; 
        ul {margin: 10px 0 0 0!important;}
    }
    ul li {display: block!important;}
    b {font-weight: 500;}
}
.result-img {max-width: 100%; display: block; margin: 0 auto; border-radius: 8px;}
.wrapper-file {position: relative; display: inline-block;
    .btn-icon {position: absolute; right: 0; top: 0;}
}
.wrapper-doc {align-items: center; justify-content: space-between; display: flex;}

//profile
.order-item {border: 1px solid $grey; border-radius: $radius; position: relative;
    .img-wrapper {width: 114px; height: 114px; min-width: 114px; padding-top: 19.5%;}
}
.order-header {background-color: $grey; border-radius: $radius; padding: 24px;}
.order-product-preview {width: 114px; height: auto; display: block;}
.msg-item {width: 100%; padding: 20px; border-radius: $radius; display: flex; align-items: center; position: relative;}
.buyer-info {padding: 0 24px 24px 24px;}
.order-content-align {padding: 24px;}
.order-content-divider {padding: 0 24px;}

.warning-msg {background-color: #D6BA8A26; color: #D6BA8A;
    svg, mat-icon {color: #D6BA8A;}
}
.success-msg {background-color: #aad9be;
    svg, mat-icon {color: #219653;}
}
.error-msg {background-color: #fba1a1;
    svg, mat-icon {color: $invalid;}
}
.user-name {min-width: 130px; height: 130px; border-radius: $radius; background-color: $secondary; display: flex; align-items: center; justify-content: center; font-size: 50px; line-height: 60px; color: #fff; font-weight: 400; text-transform: uppercase;
    &.sm {
        width: 40px; min-width: 40px; height: 40px; font-size: 14px; line-height: 40px;
    }
}
.table-item-wrapper {position: relative; overflow-y: auto; scrollbar-width: thin }
.table-item {width: 100%; 
  th {font-weight: 400; background-color: $grey; padding: 24px 15px;}
  th:not(.text-center) {text-align: left;}
  th:first-child {border-top-left-radius: $radius; border-bottom-left-radius: $radius;}
  th:last-child {border-top-right-radius: $radius; border-bottom-right-radius: $radius;}
  td {padding: 5px 15px; border-bottom: 1px solid $greyBg;}
}

app-breadcrumb {position: relative; z-index: 2;}
.breadcrumb-list {list-style: none; list-style-position: inside; padding: 0;
    li { display: inline-block; 
        span {color: $greyBg; padding-right: 10px;}
        p {padding: 0 10px;}
        a {display: block; padding: 0 10px 0 0; color: $primary;
          &.link-active {color: $secondary; cursor: default; pointer-events: none;}
        }
        &:hover a {color: $secondary;}
    }
    li:first-child a {padding-left: 0px;}
}
.video-item {position: relative; width: 100%; padding-bottom: 35%;
    video {position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 2;}
    .btn-icon {position: absolute; right: 0; top: 0; z-index: 2;}
}
.video-layer {background-color: rgba(0,0,0,0.3); position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 1;}
.video-btn {position: absolute; top: 50%; left: 50%; margin-top: -66px; margin-left: -66px; z-index: 1; width: 132px; height: 132px; border-radius: 50%; border: 1px solid #FFFFFF; 
 cursor: pointer; display: flex; align-items: center; justify-content: center; align-items: center; min-width: 132px; transition: all 0.3s ease; -webkit-transition: all 0.3s ease;
    span {content: '';width: 0; height: 0; border-top: 20px solid transparent;border-bottom: 20px solid transparent;border-left: 34px solid #fff;margin-left: 5px; transition: all 0.3s ease; -webkit-transition: all 0.3s ease;}
    &:hover {background-color: #fff;
        span {border-left: 34px solid $secondary;}
    }
}
.legitscript {display: none;}
.footer-logos .legitscript {display: block;}
// access-widget-ui::part(acsb-trigger) {position: absolute; right: calc((100% - 1430px) / 2)!important;}
access-widget-ui::part(acsb-trigger) {right: auto!important; left: 20px!important;}
.hide-chat chat-widget {display: none;}
.background-video { position: absolute; top: 0; left: 0; width: 100%; height: 100%; max-height: 100%; max-width: 100%; object-fit: cover; z-index: 1;}
.video-banner-content {position: relative; z-index: 3;}
.video-content-wrapper {max-width: 680px;}
.banner-title {font-size: 70px; line-height: 79px; color: #fff;}
.banner-text {font-size: 24px; line-height: 130%; color: #fff;}
.video-banner-main-image {position: relative;  width: 500px; z-index: 2;
    &::before, &::after {content: ''; position: absolute; left: 0; top: 75px; bottom: 0; right: 0;  transition: all 0.3s ease; border-radius: 12px;}
    &::before { backdrop-filter: blur(14.418280601501465px);} 
    &::after { background-color: var(--layer-background); opacity: var(--layer-opacity);}
    &.no-image::before {top: 0;}
    &.no-image::after {top: 0;}
    &.no-opacity::before {opacity: 0;}
}
.no-image .video-banner-main-image-wrapper {padding-top: 20px;}
.video-banner-main-image-wrapper {position: relative; z-index: 3; padding: 0 20px 20px;}

.video-banner-image img {max-width: 100%; height: auto; display: block; margin: 0 auto 20px;} 
.video-banner-label { font-size: 24px; line-height: 28px;
    p {text-align: center!important;}
    h3 {margin-top: 10px; font-size: 68px; line-height: 80px; font-weight: 400;}
}
.video-dark-layer {position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 2;}
.image-mobile {display: none;}

.left {text-align: left;
    &.video-banner-col {justify-content: start; display: flex;}
    p {text-align: left!important;}
    em {display: inline-block;}
    .video-banner-label p {text-align: center!important;}
    .banner-title {text-align: left!important;}
}
.center {margin: 0 auto; text-align: center;
    &.video-banner-col {justify-content: center; display: flex;}
    p {text-align: center!important;}
    .banner-title {text-align: center!important;}
    ul {display: flex; align-items: center; flex-direction: column;}
}
.right { text-align: right;
    &.video-banner-col {justify-content: end; display: flex;}
    p {text-align: right!important;}
    .video-banner-label p {text-align: center!important;}
    .banner-title {text-align: right!important;}
    ul {display: flex; align-items: end; flex-direction: column;}
}

// QUIZ
.form-bg {background-color: #fff; border-radius: $radius; padding: 20px; margin-bottom: 24px; position: relative;
    .loading-wrapper {background-color: #fff!important; border-radius: $radius;}
}
.form-step-title {color: $primary; opacity: 0.5; user-select: none; cursor: pointer; display: flex; align-items: center; justify-content: center; font-size: 28px; line-height: 32px; font-weight: 500;
    &.sm {font-size: 20px; line-height: 24px; text-align: left; font-weight: 400; justify-content: flex-start;}
    .counter {width: 24px; height: 24px; border-radius: 50%; background-color: $secondary; color: #fff; font-size: 12px; line-height: 24px; text-align: center; font-weight: 500;}
}
.form-step-content {display: none; padding-top: 20px; text-align: left;}
.open {
    .form-step-title {opacity: 1;}
    .form-step-content {display: block;}
}
.camera-input {position: relative; 
    input {position: absolute; left: 0; top: 0; z-index: 2; width: 100%; height: 100%; opacity: 0; cursor: pointer;}
}

@media (max-width: #{1468px}){
    .footer-bottom {padding-left: 100px;}
}
@media (min-width: #{1500px}){
    .row-offset-50.row {margin-left: -50px; margin-right: -50px;} 
    .row-offset-50.row > * {padding-left: 50px; padding-right: 50px;}
}
@media (min-width: #{1200px}){
    .fadeIn {opacity: 0; transform: translateY(15px); transition: all 0.4s ease-in-out;}
    .fadeOut {opacity: 0; transform: translateX(-25px); transition: all 0.4s ease-in-out;}
    .scaleIn {transform: scale(0.9); transition: all 0.4s ease-in-out;}
    .animated .fadeIn, .fadeIn.active {opacity: 1; transform: translateY(0px); transition-delay: calc(0.1s + var(--animated-index) * 100ms);}
    .animated .scaleIn, .scaleIn.active {transform: scale(1); transition-delay: calc(0.1s + var(--animated-index) * 100ms);}
    .animated .fadeOut, .fadeOut.active {opacity: 1; transform: translateX(0px); transition-delay: calc(0.1s + var(--animated-index) * 100ms);}
    .scaleFade {opacity: 0; transform: scale(1.2); transition: all 0.5s cubic-bezier(.1, .6, .4, 1);
        &.active {opacity: 1; transform: scale(1); transition-delay: calc(0.1s + var(--animated-index) * 100ms);}
    }
    .offsetFade {opacity: 0; transform: translateX(-25%); transition: all 0.5s cubic-bezier(.1, .6, .4, 1);
        &.active {opacity: 1; transform: translateX(0%); transition-delay: calc(0.1s + var(--animated-index) * 100ms);}
    }
    .sub-menu-link:hover, .active .sub-menu-link {color: $secondary;}
    .arch-btn-align {transform: translateY(-100%);}
    .video-banner-main-image::after, .video-banner-main-image::before {transform: translateY(-30px);}
    .active.video-banner-main-image::after, .active.video-banner-main-image::before {transform: translateY(0px);}
}
@media (min-width: #{992px}){
    .block-desktop {display: block;} 
    .block-mobile {display: none;} 
}
@media (max-width: #{1500px}){
    .cookies-popup {left: 15px; bottom: 15px;}
    .page-wrapper {max-width: none;}
    .button-top {right: 90px; }
    .benefits {
        .benefit-0 {left: 0;}
        .benefit-1 {left: 0;}
        .benefit-2 {left: 0;}
        .benefit-3 {right: 0;}
        .benefit-4 {right: 0;}
        .benefit-5 {right: 0;}
    }
    .banner-title {font-size: 40px; line-height: 50px;
        &.sm {font-size: 35px; line-height: 43px;}
    }
    .sub-menu-link {font-size: 12px; padding-left: 10px; padding-right: 10px;}
    .main-banner-wrapper {min-height: 400px;}
    .video-banner-main-image {width: 400px;}
    .video-banner-main-image-wrapper {padding: 10px!important;}
    .video-banner-label h3 {font-size: 32px; line-height: 44px;}
    .video-banner-label {font-size: 18px; line-height: 22px;}
    .video-banner-image img {max-width: 70%;}
}
@media (max-width: #{1200px}){
    .button-top {display: none;}
    .parallax-container {pointer-events: none;}
    :root {--top-offset: 60px;}
    .item-hover-banner {display: none;}
    .img-label-wrapper {height: 100%;}
    .banner-col-left, .banner-col-right {width: 50%;}
    .logo-item-col {width: 25%;}
    .form-wrapper {padding: 40px 30px;}
    .row-lg, .row-lx, .row-lxx {--bs-gutter-x: 30px;}
    .article-content {
        blockquote {font-size: 18px; line-height: 24px; padding-left: 80px; }
        table {
            tr th,  tr td {height: 60px!important; font-size: 16px; padding: 0 16px!important;}
        }
    }
    .range-price-item .range-row-price .simple-link-hover {opacity: 1;}
    .fixed-element {position: relative!important; top: 0!important; min-width: auto!important;}
    .sub-menu-link {justify-content: space-between; border-bottom: 1px solid $greyBg; padding: 16px 14px;}
    .banner-link-layer {display: none;}
    .banner-link {padding: 16px; pointer-events: none; 
        .banner-link-title {width: 65px; height: 65px;
            &:after {left: 4px; top: 4px; right: 4px; bottom: 4px;}
            &:before {display: none;}
            p {font-size: 12px;}
        }
        .banner-link-text {font-size: 14px; line-height: 20px;}
    }
    .banenr-hover-desc {padding-right: 0px; font-size: 13px; line-height: 18px;}
    .promo-offset .header-desktop {padding-top: 32px;}
    .promo-offset .menu-search {padding-top: 45px!important;}
    .banner-video-wrapper, .promo-offset .banner-video-wrapper {height: auto!important; padding: 80px 0;}
}
@media (max-width: #{992px}){
    .block-desktop {display: none;}
    .block-mobile {display: block;} 
    .benefits {width: auto; height: auto; margin: 0 -15px; display: inline-block;
        .benefit-item {position: relative; top: auto; left: auto; right: auto; width: 50%; float: left; padding: 0 15px 15px;}
        .circle {display: none;}
    }
    .benefit-item-wrapper {width: 100%;}
    .logo-item-col {width: 50%;} 
    .image-label .benefit-item, .arch-circle {display: none;}
    .arch-wrapper .arch-item-offset {margin-top: 0px;}
    .simple-header {padding: 15px;}
    .profile-quiz {
        .survey-footer { padding: 16px;}
        .survey-footer .survey-footer-btns {max-width: none;}
    }
    .bg-text-item {padding: 50px;}
    .order-header {padding: 15px;}
    .order-header .col {flex: auto; padding-bottom: 5px;}
    .doc-file {margin-bottom: 10px;}
    .order-content-align {padding: 15px;}
    .buyer-info {padding: 0 15px 15px 15px;}
    .buyer-info .d-flex {flex-wrap: wrap; margin-bottom: 5px;}
    .order-content-divider {padding: 0 15px;}
    .footer-bottom {padding-left: 0px;} 
}
@media (max-width: #{768px}){
    .benefit-item {width: 100%;}
    .review-slider-nav {position: relative; margin-top: 30px; justify-content: center;}
    .range-price-option {display: none;}
    .footer-logos #legitscript {width: 100%;}
    .option-text, .quiz-header {display: block;
        h4 {margin-bottom: 10px;}
    }
    .article-content {
        table {
            tr th,  tr td {height: 40px!important; font-size: 13px!important; padding: 0 10px!important;}
        }
        iframe {height: 260px;}
    }
    .content-profile-header {flex-wrap: wrap; flex-direction: column; align-items: flex-start!important;
        h5 {width: 100%; margin-bottom: 20px;}
        .justify-content-end {justify-content: flex-start!important;}
    }
    app-price-item {margin-bottom: 20px; display: block;}
    .video-btn {width: 80px; height: 80px; min-width: 80px; margin-top: -40px; margin-left: -40px;
        span {border-top: 15px solid transparent;border-bottom: 15px solid transparent;border-left: 24px solid #fff;}
    }
    .row-20 {--bs-gutter-x: 10px;}
    .main-banner {padding-top: 20px;}
    .main-banner-wrapper {flex-direction: column-reverse;
        .row {flex-direction: column-reverse;}
    }
    app-page-banner {
        .img-wrapper {margin-bottom: 20px;}
    }
    .banner-col-left, .banner-col-right {width: 100%!important;}
    .banner-col-right {margin-bottom: 57px;}
    .banner-col-left {padding-right: 0;}
    .banner-title {font-size: 34px; line-height: 44px;
        &.sm {font-size: 26px; line-height: 30px;}
    }
    .bg-main-banner {background-position: left 0 bottom 100px;}
    .banner-link {margin-bottom: 10px; height: auto;}
    .main-banner-image-bg {width: 100%;}
    .main-banner-label {width: 109px; height: 109px; top: -37px; font-size: 10px; line-height: 14px;
      &.center {margin-left: -54.5px;} 
    }
    .main-banner-image-product {bottom: -37px; max-width: 151px!important;}
    .banner-description-list ul li {font-size: 14px; line-height: 18px; padding: 5px 0 5px 50px;}
    .hidden-image-mobile, .hidden-video-mobile video {display: none!important;}
    .image-mobile {display: block;}
    .image-descktop {display: none;}

    .video-banner-main-image.no-image-mobile::before {top: 0;}
    .video-banner-main-image.no-image-mobile::after {top: 0;}
    .no-image-mobile .video-banner-main-image-wrapper {padding-top: 20px;}

}
@media (max-width: #{576px}){
    .works-item,.feature-item, .works-item.simple {min-height: auto;}
    .two-img-wrap {margin-bottom: 30px;}
    .bg-text-item {padding: 15px;}
    .auth-align-md {text-align: center;width: 100%;flex-wrap: wrap;flex-direction: column-reverse;
        .app-button {width: 100%; margin-bottom: 20px;}
    }
    .dialog-btn-align {display: flex; flex-direction: column-reverse;}
    .dialog-btn-align .app-button {margin: 10px 0px;}
    .form-wrapper {padding: 40px 15px;}
    .slider-auto-width, .full-width-container swiper-slide, .dialog-container .app-button {width: 100%;}
    .product-slider {flex-direction: column-reverse;}
    .product-preview, .product-thumbs {width: 100%;}
    .product-thumbs swiper-container {height: auto!important;}
    .product-thumbs swiper-slide {width: auto;}
    .product-preview swiper-slide {height: auto;}
    .simple-header-logo {max-width: 140px;}
    .item-simple, .item-border {padding: 16px;}
    .align-md-left {flex-direction: column; align-items: flex-start!important; position: relative; padding-right: 50px;
        .align-md-left-btn {position: absolute; top: 0; right: 0;}
    }
    .quiz-item-title {width: 100%; flex-direction: column;
        h4 {width: 100%; margin: 10px 0 5px; }
        .simple-link {margin-left: 0!important;}
    }
    .flex-row-reverse-mb {flex-direction: column-reverse; 
       .image-label {margin-bottom: 20px;}
    }
    .how-it-work-item {min-height: auto; 
        .icon {margin-bottom: 20px;}
    }
    .content-profile-header {width: 100%;
     > * {width: 100%;}
    }
    .buyer-info .d-flex {justify-content: flex-start!important;} 
    .form-bg {padding: 15px 10px;}
    .banner-description-list ul li {min-width: 100%;}
    .banner-title strong {font-weight: 700;}
    .selectable-product {height: auto!important; display: block;}
    .form-step-title {font-size: 20px; line-height: 24px;}
    .video-banner-content .row {flex-direction: column-reverse;}
    .video-banner-content .d-flex.justify-content-end {justify-content: center!important;}
    .banner-video-wrapper, .promo-offset .banner-video-wrapper {padding: 110px 0 60px;}
    .video-banner-main-image {margin-bottom: 30px;}
    .banner-text {font-size: 18px; line-height: 24px;}
    .video-banner-col {width: 100%; flex-direction: column;}
}
@media (max-width: #{480px}){
    .slider-nav {display: none;}
    .slider-navigation {padding: 0;}
    .review-item, .feature-item {padding: 15px;} 
    
    .cookies-popup {width: auto; right: 10px; bottom: 10px; left: 10px;}
    .logo-item-col {width: 100%;}
    .article-content {
        blockquote {font-size: 16px; line-height: 20px; padding-left: 60px; }
        table {
            tr th,  tr td {height: 60px; font-size: 14px; padding: 0 10px;}
        }
    }
    .content-profile-header .app-button {width: 100%; margin: 0 0 10px 0!important;}
    app-city-dialog .col-auto, .benefits .benefit-item {width: 100%;}
    .selectable-product {height: 100%;
        .img-wrapper {height: 115px!important; max-width: 115px!important;}
        .test-item-title {padding: 8px;}
        .test-item {padding: 10px; min-height: auto; height: 100%; }
        .test-item-title h5 {font-size: 13px;}
        .test-item-title p {font-size: 12px;}
    }
    .survey-footer {height: 70px; padding: 8px;}
    .video-item {padding-bottom: 60%;}
    .video-banner-main-image {width: 100%;}
    .banner-title {font-size: 30px; line-height: 40px;}
}
@media (max-width: #{380px}){
    .app-button {font-size: 14px; padding-left: 15px!important; padding-right: 15px!important; min-height: 40px!important;} 
    .add-banner {font-size: 12px;}
}
@media (max-width: #{320px}){
    .app-button {padding-left: 8px!important; padding-right: 8px!important; font-size: 13px;}
}

.mobile .descktop-item {display: none!important;}
.mobile-item {display: none!important;}
.mobile .mobile-item {display: block!important;}

.contrast {
    .accordion-item {background-color: #fff!important; border-color: #fff!important;}
    .benefit-promo-wrapper {background-color: #fff!important;}
}

