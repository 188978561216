$primary: #252D41;
$secondary: #7FA3EE;
$invalid: #EB5757;
$success: #219653;
$warning: #F2B45E;
$hover: #7692C2;
$input: #667480;
$lighter: #3E4C58;
$bodyBg: rgba(242, 242, 242, 0.6);
$grey:#EFF2F7;
$greyBg: #DEE3EC;
$whiteLighter:rgba(255,255,255,0.6);

$space: 25px;
$radius: 8px;

$xs: 575px;
$sm: 767px;
$md: 991px;
$lg: 1199px;
$xl: 1370px;

// $mat-primary: (
//   50 : #007ABE,
//   100 : #007ABE,
//   200 : #007ABE,
//   300 : #007ABE,
//   400 : #10499C,
//   500 : #10499C,
//   600 : #10499C,
//   700 : #10499C,
//   800 : #10499C,
//   900 : #10499C4D,
//   A100 : #ffc4c4,
//   A200 : #ff9191,
//   A400 : #ff5e5e,
//   A700 : #ff4545,
//   contrast: (
//     50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #ffffff,
//     400 : #ffffff,
//     500 : #ffffff,
//     600 : #ffffff,
//     700 : #ffffff,
//     800 : #ffffff,
//     900 : #ffffff,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #000000,
//     A700 : #ffffff,
//   )
// );

// $mat-accent: (
//   50 : #e0f3ea,
//   100 : #b3e2cb,
//   200 : #80cea9,
//   300 : #4dba86,
//   400 : #26ac6c,
//   500 : #009d52,
//   600 : #00954b,
//   700 : #008b41,
//   800 : #008138,
//   900 : #006f28,
//   A100 : #9effb8,
//   A200 : #6bff93,
//   A400 : #38ff6e,
//   A700 : #1fff5b,
//   contrast: (
//     50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #000000,
//     400 : #ffffff,
//     500 : #ffffff,
//     600 : #ffffff,
//     700 : #ffffff,
//     800 : #ffffff,
//     900 : #ffffff,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #000000,
//     A700 : #000000,
//   )
// );

// $mat-warn: (
//   50 : #fee8e7,
//   100 : #fcc7c3,
//   200 : #faa19b,
//   300 : #f77b72,
//   400 : #f65f54,
//   500 : #f44336,
//   600 : #f33d30,
//   700 : #f13429,
//   800 : #ef2c22,
//   900 : #ec1e16,
//   A100 : #ffffff,
//   A200 : #ffe9e9,
//   A400 : #ffb8b6,
//   A700 : #ff9f9c,
//   contrast: (
//     50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #000000,
//     400 : #000000,
//     500 : #ffffff,
//     600 : #ffffff,
//     700 : #ffffff,
//     800 : #ffffff,
//     900 : #ffffff,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #000000,
//     A700 : #000000,
//   )
// );

// bootstrap
