@use 'variables' as *;

:root {
  --h1-size: 50px;
  --h1-height: 60px;
  --h2-size: 40px;
  --h2-height: 50px;
  --h3-size: 26px;
  --h3-height: 34px;
  --h4-size: 22px;
  --h4-height: 30px;
  --h5-size: 18px;
  --h5-height: 22px;
  --h6-size: 16px;
  --h6-height: 20px;
  --text-size: 16px; 
  --text-height: 24px;
  --text-xs-size: 12px; 
  --text-xs-height: 16px;
  --text-sm-size: 14px; 
  --text-sm-height: 20px;
  --text-lg-size: 18px; 
  --text-lg-height: 28px;
  --text-lx-size: 20px; 
  --text-lx-height: 28px;
  --weight-light: 300;
  --weight-normal: 400;
  --weight-semi: 500;
  --weight-bold: 700;
  --letter-spacing: 0.5px;
  --layer-background: '';
  --layer-opacity: '';
}
html,
body { width: 100%;}
body {position: relative;}
body{color: $primary; margin: 0;font-family: "Poppins", sans-serif;}
*, *:before, *:after{box-sizing: border-box; margin: 0;}
html{overflow-y: scroll; scroll-behavior: smooth;}
p {margin: 0;}
body, .text{font-size: var(--text-size); line-height: var(--text-height); font-weight: var(--weight-light);} 
.text-xs{font-size: var(--text-xs-size); line-height: var(--text-xs-height);}
.text-sm{font-size: var(--text-sm-size); line-height: var(--text-sm-height);}
.text-lg{font-size: var(--text-lg-size); line-height: var(--text-lg-height);}
.text-lx{font-size: var(--text-lx-size); line-height: var(--text-lx-height);}
.app-list {width: 100%; list-style-position: inside; padding-left: 0px; margin-bottom: 15px;
  li {margin-bottom: 10px; display: block; position: relative; padding-left: 25px;
        &:after {content: ''; position: absolute; left: 0; top: 10px; width: 5px; height: 5px; border-radius: 50%; background-color: $secondary;}
  }
}
h1, h2, h3, h4, h5, h6{margin: 0; font-weight: var(--weight-normal); color: $primary;}
h1{font-size: var(--h1-size); line-height: var(--h1-height);}
h2{font-size: var(--h2-size); line-height: var(--h2-height);}
h3{font-size: var(--h3-size); line-height: var(--h3-height);}
h4{font-size: var(--h4-size); line-height: var(--h4-height);}
h5{font-size: var(--h5-size); line-height: var(--h5-height);}
h6{font-size: var(--h6-size); line-height: var(--h6-height);}
.banner-title {font-size: 60px; line-height: 78px; font-weight: var(--weight-semi);
  strong {font-weight: 700; color: $secondary;}
  &.sm {font-size: 52px; line-height: 57px;}
}
a{cursor: pointer; color: currentColor; text-decoration: none;}
a.hover:hover, a.hover:focus{text-decoration: none;}
.pointer {cursor: pointer;}
b{font-weight: var(--weight-bold);}
.normal-weight {font-weight: var(--weight-normal);}
.semi-weight, strong {font-weight: var(--weight-semi);}
.hidden {display: none!important;}
.italic {font-style: italic;}
.text-left{text-align: left;}
.text-right{text-align: right!important;}
.text-center{text-align: center;}
.text-through{text-decoration: line-through;}
.text-underline{text-decoration: underline;}
.vertical-bar{width: 1px; background: $greyBg;}
.word-break {word-break: break-all;}
.pre-wrap {white-space: pre-wrap}
.divider {display: block; width: 100%; height: 1px; background: $greyBg;
  &.lg {height: 4px;}
}
.divider-contrast {display: block; width: 100%; height: 1px; background: $lighter;}
.white-divider {width: 100%; height: 14px; background-color: #FFFFFF; opacity: 0.1;}
.text-uppercase{text-transform: uppercase;}
.text-lowercase{text-transform: lowercase;}
.text-nowrap{white-space: nowrap;}
.text-truncate{display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.lighter {color: $lighter;}
.color-contrast {color: $whiteLighter;}
.color-success {color: $success;}
.color-primary {color: $primary;}
.color-secondary {color: $secondary;}
.color-white {color: #fff;}
.color-invalid {color: $invalid;}
.color-warning {color: $warning;}
.color-hover {color: $hover;}
.bg-primary {background-color: $primary;}
.bg-secondary {background-color: $secondary;}
.bg-grey {background-color: $grey;}
.crossed {position: relative; display: inline-block;
  :before {content: ''; position: absolute; left: 0; top: 50%; height: 4px; width: 100%; background-color: $secondary;}
}
.text-line-1 {text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}
.text-line-2 {text-overflow: ellipsis; display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical; overflow: hidden;}
.text-line-3 {text-overflow: ellipsis; display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical; overflow: hidden;}
.text-line-5 {text-overflow: ellipsis; display: -webkit-box;-webkit-line-clamp: 5;-webkit-box-orient: vertical; overflow: hidden;}
.form-title {font-size: 14px; text-transform: uppercase; font-weight: 400; letter-spacing: 1.5px; margin-bottom: 20px;}

@media (max-width: #{1200px}){
    :root {
    --h1-size: 40px;
    --h1-height: 50px;
    --h2-size: 30px;
    --h2-height: 40px;
    --h3-size: 20px;
    --h3-height: 24px;
    --h4-size: 18px;
    --h4-height: 22px;
    --h5-size: 16px;
    --h5-height: 20px;
    --h6-size: 14px;
    --h6-height: 18px;
    --text-size: 14px; 
    --text-height: 20px;
    --text-sm-size: 13px; 
    --text-sm-height: 18px;
    --text-lg-size: 16px; 
    --text-lg-height: 24px;
    --text-lx-size: 18px; 
    --text-lx-height: 24px;
    }
}

@media (max-width: #{768px}){
    :root {
    --h1-size: 30px;
    --h1-height: 34px;
    --h2-size: 22px;
    --h2-height: 28px;
    }
}

@media (max-width: #{576px}){
  :root {
    --text-lg-size: 14px; 
    --text-lg-height: 20px;
    --text-lx-size: 14px; 
    --text-lx-height: 20px;
  }
}


